export const REGION = 'eu-central-1';
const IDENTITY_POOL_ID = "eu-central-1:5f85848b-b340-4ea7-9e68-6715df5f4cbf";

// TODO [rafalw:] this will be moved to https://api.coral-system.com
const YETISENSE_APIGATEWAY_URL = "https://api.yetisense.com"
const CORAL_API_URL = "https://coral.yetisense.com"
const MARINE_TRAFFIC_EXPORT_APIGATEWAY_URL = "https://n3wyrhcskl.execute-api.eu-central-1.amazonaws.com/dev"

const awsconfig = {
  Auth: {
    mandatorySignIn: true,
    region: REGION,
    userPoolId: "eu-central-1_xGewPp1rb",
    identityPoolId: IDENTITY_POOL_ID,
    userPoolWebClientId: "18m4i5p2646smklnql451op030"
  },
  API: {
    endpoints: [
      {
        name: "coral",
        endpoint: CORAL_API_URL + "/api",
        region: REGION
      },
      {
        name: "openData",
        endpoint: YETISENSE_APIGATEWAY_URL + "/water",
        region: REGION
      },
      {
        name: "reports",
        endpoint: CORAL_API_URL + "/reports",
        region: REGION
      },
      {
        name: "iLayers",
        endpoint: CORAL_API_URL + "/ilayers",
        region: REGION
      },
      {
        name: "water",
        endpoint: CORAL_API_URL + "/water",
        region: REGION
      },
      {
        name: "episodes",
        endpoint: CORAL_API_URL + "/episodes",
        region: REGION
      },
      {
        name: "map",
        endpoint: CORAL_API_URL + "/map",
        region: REGION
      },
      {
        name: "risk",
        endpoint: CORAL_API_URL + "/risk",
        region: REGION
      },
      {
        name: "iot",
        endpoint: CORAL_API_URL + "/iot" ,
        region: REGION
      },
      {
        name: "camera",
        endpoint: CORAL_API_URL + "/camera" ,
        region: REGION
      },
      {
        name: "surveys",
        endpoint: CORAL_API_URL,
        region: REGION
      },
      {
        name: "marine-traffic",
        endpoint: MARINE_TRAFFIC_EXPORT_APIGATEWAY_URL,
        region: REGION
      },
      {
        name: 'maintenance',
        endpoint: CORAL_API_URL + '/maintenance',
        region: REGION
      }
    ]
  },
  Storage: {
    bucket: 'yeti-reports',
    region: REGION,
  }
  // Storage: {
  //   bucket: 'dock-mgmt-files',
  //   region: REGION,
  // }
}


export default awsconfig;
