import React, {useEffect, useState} from 'react';
import Highcharts from 'highcharts/highstock';

import applyExporting from 'highcharts/modules/exporting';
import applyOffline from 'highcharts/modules/offline-exporting';
import Box from '@mui/material/Box';
import {primaryColor} from '../../assets/jss/nextjs-material-dashboard';
import {useTranslation} from 'react-i18next';
import Meteogram from '../Meteogram/Meteogram';
import {getWeatherStation} from '../../api';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment/moment';
import {Typography} from '@mui/material';
import WindRoseChart from './WindRoseChart';


applyExporting(Highcharts);
applyOffline(Highcharts);


export default function WeatherStationChart({device, selectedDate}) {
  // const {clientId} = useConfig().state.config;
  const clientId = 'ugw';
  const [weatherData, setWeatherData] = useState();
  const [loading, setLoading] = useState(true);

  const title = device.name;

  // Pobranie danych meteo
  useEffect(() => {
    getWeatherStation(clientId, device.serialNo, '2024-07-12')
      .then(result => setWeatherData(result))
      .finally(() => setLoading(false));
  }, [device, selectedDate]);

  return (<Box position={'relative'}>
      <Typography variant={'h6'}
                  sx={{'@media screen and (max-width: 50em)': {fontSize: '1rem'}}}
                  style={{marginTop: -40, color: primaryColor[2]}}>
        {title} {moment(selectedDate).format('YYYY-MM-DD')}
      </Typography>

      {loading ? <CircularProgress size={100} sx={{ marginTop: 10}}
      /> : <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Meteogram data={weatherData}/>
        <WindRoseChart device={device} selectedDate={selectedDate} />
      </Box>}
    </Box>
  );
}
