import React from 'react';
import {Popup} from '@urbica/react-map-gl';
import {Divider, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import './DeviceInfoPopup.css';
import {useMeasurementsContext} from '../../context/measurements/measurementsContext';
import {
  AGRO_PARAMS,
  MeasurementsUnits,
  METEO_PARAMS_FULL,
  ThresholdByMeasurement,
  ValueSlider,
  windDir
} from '../../components/measurementsUtil';
import {useTranslation} from 'react-i18next';
import {useMapContext} from '../../context/map/mapContext';
import WaterCamera from '../../assets/images/water-camera-white.png';
import {warningColor} from '../../assets/jss/nextjs-material-dashboard';
import {Construction} from '@mui/icons-material';
import {useMaintenance} from '../../context/maintenance/maintenanceContext';


export default function DeviceInfoPopup({device}) {
  const {t} = useTranslation();
  const {activeMaintenances} = useMaintenance().state;
  const {serialNo, name, location, measurements, weatherMeasurements} = device;
  const {state: {sliderPosition, last24, selectedDate}} = useMeasurementsContext();
  const {state: {selectedMarkers}} = useMapContext();
  const readValueSafe = measurement => last24[serialNo] && last24[serialNo][measurement] && last24[serialNo][measurement][sliderPosition] && last24[serialNo][measurement][sliderPosition][1]?.toFixed(2);
  const timestamp = last24[serialNo] && last24[serialNo][measurements[0]] && last24[serialNo][measurements[0]][sliderPosition] && new Date(last24[serialNo][measurements[0]][sliderPosition][0]) || selectedDate;

  return <Popup
    latitude={location[1]}
    longitude={location[0]}
    anchor={'bottom'}
    // className={'deviceInfoPopup'}
    offset={[0, -15]}
    closeButton={false}
    closeOnClick={false}
    maxWidth={'450px'}
  >
    <Box
      minWidth={300}
      px={2}
      py={0.5}
    >
      {serialNo.includes('ZPG_WATER') && <Box sx={{position: 'absolute', right: 10}}>
        <img height={'25px'} src={WaterCamera} alt={'Camera on Water'}/>
      </Box>}

      {activeMaintenances[serialNo] && <Box maxWidth={300} mb={2}>
        <Box display={'flex'} gap={10}>
          <Construction sx={{color: warningColor[0]}}/>
          <Typography sx={{justifySelf: 'center'}} color={warningColor[0]} variant={'h6'}>Serwis</Typography>
        </Box>
        <Typography color={warningColor[0]} variant={'body2'}>{activeMaintenances[serialNo].initialText}</Typography>
      </Box>}

      <Typography variant={'h6'}>{name}</Typography>
      <Typography variant={'caption'}>{timestamp.toLocaleString()}</Typography>
      <Box mt={2} display={'flex'} flexDirection={'column'} gap={2.5}>
        {[...(measurements || []), ...(weatherMeasurements || [])]
          .filter(m => ['PM10', 'PM25'].includes(m) ? selectedMarkers.includes('AIR') : true)
          .filter(m => AGRO_PARAMS.includes(m) ? selectedMarkers.includes('AGRO') : true)
          .filter(m => METEO_PARAMS_FULL.includes(m) ? selectedMarkers.includes('METEO') : true)
          // .filter(m => deviceMeasurements[m] && ThresholdByMeasurement[m])
          .map(m => <Box key={m}>
            <Box>
              {m === 'dhtHumidity' && <Divider color={'whitesmoke'} sx={{my: 2, width: '100%'}}/>}
              <Typography display={'inline'} fontWeight={'bold'}
                          variant={'body2'}>{t('measurements.parameters', {returnObjects: true})[m] || m}: </Typography>
              <Typography display={'inline'} variant={'body2'}>
                {/*{readValueSafe(m) || '-:-'}*/}
                {m === 'windDirectionMed' ? windDir(readValueSafe(m) || 0) : readValueSafe(m) || 0}
                {' '}
                {MeasurementsUnits[m] || 'µg/m³'}
              </Typography>
            </Box>
            {ThresholdByMeasurement[m] && readValueSafe(m) && <ValueSlider
              measurementType={m}
              // value={readValueSafe(m) || '-:-'}
              value={readValueSafe(m) || 0}
            />}
          </Box>)}
      </Box>
    </Box>
  </Popup>;
};
