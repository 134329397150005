import React, {useEffect, useMemo, useState} from 'react';
import Box from '@mui/material/Box';
import Header from '../components/Header/Header';
import {useConfig} from '../context/clientConfig/clientConfigContext';
import {useTranslation} from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableContainer,
  Tooltip,
  Typography
} from '@mui/material';
import DatePicker from 'react-datepicker';
import {
  Add,
  BubbleChartOutlined,
  CalendarMonth,
  Cloud,
  Edit,
  ExpandLess,
  ExpandMore,
  LocationOn,
  Water
} from '@mui/icons-material';
import moment from 'moment/moment';
import {getAllMaintenance} from '../api';
import fnBrowserDetect from '../browserDetect';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {getComparator, stableSort, TCell, THeader} from '../components/Table/tableUtills';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import {grayColor, primaryColor, successColor, warningColor} from '../assets/jss/nextjs-material-dashboard';
import {visuallyHidden} from '@mui/utils';
import TableBody from '@mui/material/TableBody';
import {DevicesCell} from '../components/Notifications/NotifsConfigTable';
import _ from 'lodash';
import {DONE, IN_PROGRESS, NEW, STARTED} from './MaintenanceStatus';
import humanizeDuration from 'humanize-duration';
import WaterCamera from '../assets/images/water-camera.png';
import MaintenancePanel from '../components/MaintenancePanel/MaintenancePanel';
import ExcelIcon from '../assets/images/Excel-icon.png';
import {initialMaintenanceObj, useMaintenance} from '../context/maintenance/maintenanceContext';
import {INCIDENT, SERVICE, UNKNOWN} from './MaintenanceType';
import {isAdmin} from '../utils';

export default function Maintenance() {
  const {t, i18n} = useTranslation();
  const {clientId, config: {devices, retiredDevices}, email} = useConfig().state;
  const {state: {selectedMaintenance}, dispatch: maintenanceDispatch} = useMaintenance();
  const devicesDict = useMemo(() => _.keyBy([...devices, ...(retiredDevices || [])], 'serialNo'), [devices, retiredDevices]);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [maintenanceList, setMaintenanceList] = useState([]);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('timestamp');

  const setSelectedMaintenance = m => maintenanceDispatch({type: 'set-selectedMaintenance', selectedMaintenance: m});

  moment.locale(i18n.resolvedLanguage);
  const onDateChange = date => setSelectedDate(date);
  const showPanelModal = (m) => setSelectedMaintenance(m);

  const fetchMaintenance = () => {
    setLoading(true);
    getAllMaintenance(clientId,
      moment(selectedDate).startOf('month').format('YYYY-MM-DD'),
      moment(selectedDate).endOf('month').format('YYYY-MM-DD'))
      .then(result => setMaintenanceList(result))
      .finally(() => setLoading(false));
  };
  const closePanelModal = (refresh) => {
    setSelectedMaintenance(null);
    setLoading(true);
    if (refresh) {
      fetchMaintenance();
    }
  };

  useEffect(() => {
    fetchMaintenance();
  }, [clientId, selectedDate]);

  // console.log(maintenanceList);
  const rows = maintenanceList.map(({
    serialNo,
    createdAt,
    createdBy,
    reactedAt,
    updatedAt,
    updatedBy,
    status,
    type,
    initialText,
    endPhoto,
    startPhoto,
    description,
    notes
  }, idx, list) => ({
    maintenanceObj: list[idx],
    createdAt,
    createdAtDate: moment(createdAt).format('ll'),
    createdAtTime: moment(createdAt).format('dddd HH:mm'),
    createdBy,
    reactedAt,
    updatedAt,
    updatedAtDate: moment(updatedAt).format('ll'),
    updatedAtTime: moment(updatedAt).format('dddd HH:mm'),
    timeAgo: moment(updatedAt || createdAt).fromNow(),
    updatedBy,
    serialNo,
    status,
    type: type || UNKNOWN,
    initialText,
    photos: [...(startPhoto || []), ...(endPhoto || [])],
    description,
    notes
  }));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const newMaintenance = () => {
    setSelectedMaintenance(initialMaintenanceObj(clientId, email));
  };

  // TITLE: Name, DatePicker
  const titleComponent = <Box display={'flex'} gap={5} justifyContent={'space-between'} alignItems={'center'}>
    <Box>
      <Typography color={'primary'} variant={'h5'}>
        Prace serwisowe
      </Typography>
    </Box>
    <Box>
      <DatePicker
        customInput={
          <Button variant={'outlined'} endIcon={<CalendarMonth/>}>
            {moment(selectedDate).format('MMMM YYYY')}
          </Button>
        }
        showMonthYearPicker
        locale={i18n.resolvedLanguage}
        onChange={onDateChange}
        selected={selectedDate}
        maxDate={new Date()}
        minDate={moment('2024-08-01').toDate()}
      />
    </Box>
    <Box display={'flex'} gap={5} justifySelf={'flex-end'}>
      <Tooltip title={'Export do Excela - Już w krótce'}>
        <Button style={{color: primaryColor[2]}}>
          <img width={30} src={ExcelIcon}/>
        </Button>
      </Tooltip>
      {email === 'rwolak@seadata.pl' &&
        <Button onClick={newMaintenance} variant={'contained'} color={'warning'} startIcon={<Add/>}>
          Otwórz serwis</Button>}
    </Box>
  </Box>;

  return <>
    <Header/>
    <Box sx={{
      padding: 1,
      width: '100vw',
      height: 'calc(100vh - 88px)',
      '@media screen and (max-width: 50em)': {
        height: fnBrowserDetect() === 'safari' ? 'calc(100vh - 120px)' : 'calc(100vh - 60px)'
      }
    }}>
      <Card elevation={0} sx={{minHeight: '100%'}}>
        <CardHeader
          title={titleComponent}
          // EXCEL ICON - GENERATE REPORT action={<Close sx={{cursor: 'pointer'}} color={'error'} onClick={onClose}/>}
          // Moze dodać statystyki ile serwsow i ile czasu czas reakcji: subheader={selectedView === 'photos' ? photosSubheader : replaySubheader}
        />
        <CardContent>
          {loading ? <Box sx={{position: 'absolute', top: '40%', left: '40%', zIndex: 1400}}>
            <CircularProgress variant={'indeterminate'} size={200}/>
          </Box> : <Box><TableContainer>
            <Table aria-label="Maintenance table">
              <TableHead>
                <TableRow sx={{backgroundColor: 'whitesmoke'}}>
                  <TableCell width={20}><THeader>#</THeader></TableCell>
                  {[{id: 'createdAt', label: 'createdAt'},
                    {id: 'stationName', label: 'stationName'},
                    // {id: 'status', label: 'status'},
                    {id: 'photos', label: 'photos'},
                    {id: 'desc', label: 'desc'},
                    {id: 'updatedAt', label: 'updatedAt'},
                    {id: 'type', label: 'type'},
                    {id: 'reactionTime', label: 'reactionTime'}
                  ].map(({id, label}) =>
                    <TableCell key={id} sortDirection={orderBy === id ? order : false}
                               align="left">
                      <TableSortLabel
                        active={orderBy === id}
                        direction={orderBy === id ? order : 'asc'}
                        onClick={event => handleRequestSort(event, id)}
                      >
                        <Typography sx={{color: primaryColor[2]}}>
                          {t('maintenance.' + label)}
                        </Typography>
                        {orderBy === id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .map(({
                    maintenanceObj,
                    createdAt,
                    createdAtDate,
                    createdAtTime,
                    createdBy,
                    reactedAt,
                    updatedAt,
                    updatedAtDate,
                    updatedAtTime,
                    timeAgo,
                    updatedBy,
                    serialNo,
                    status,
                    type,
                    initialText,
                    photos,
                    description,
                    notes,
                  }, idx) => (
                    <TableRow
                      key={idx}
                      selected={[STARTED, IN_PROGRESS].includes(status)}
                      sx={{
                        backgroundColor: status === NEW ? 'rgba(255, 165, 0, 0.3)' : 'transparent',
                        '&:last-child td, &:last-child th': {border: 0}
                      }}>
                      <TableCell width={20} align={'center'}>
                        <TCell>{idx + 1}</TCell>
                        {isAdmin(email)  && <IconButton
                          color={'secondary'}
                          onClick={() => showPanelModal(maintenanceObj)}
                          aria-label="Edit Maintenance"
                        >
                          <Edit/>
                        </IconButton>}
                      </TableCell>
                      <TableCell width={200} align="left">
                        <TCell>{createdAtDate}</TCell>
                        <TCell>{createdAtTime}</TCell>
                        <br/>
                        {initialText && <Typography sx={{
                          maxWidth: '200px',
                          wordWrap: 'break-word',
                          overflowWrap: 'break-word',
                        }} color={grayColor[1]}>Rozpoznanie: {initialText}</Typography>}
                      </TableCell>
                      <TableCell width={200} align="center">
                        <DevicesCell stations={[serialNo]} devicesDict={devicesDict}/>
                        {iconsByDevice(devicesDict[serialNo])}
                      </TableCell>
                      {/*<TableCell width={120} align="left">*/}
                      {/*  <Typography fontWeight={'bold'} color={status === NEW ? warningColor[0] : grayColor[1]}>*/}
                      {/*    {t('maintenance.statusLabels', {returnObjects: true})[status]}*/}
                      {/*  </Typography>*/}
                      {/*</TableCell>*/}
                      <TableCell width={300} align="center">
                        <PhotosCell photos={photos}/>
                      </TableCell>
                      <TableCell
                        align="left"
                        // sx={{  alignItems: 'space-around'}}
                      >
                        <DescriptionCell status={status} description={description} notes={notes} t={t}/>
                      </TableCell>
                      <TableCell width={200} align="left">
                        <TCell>{updatedAtDate}</TCell>
                        <TCell>{updatedAtTime}</TCell>
                        <Typography variant={'caption'} color={grayColor[0]}>{timeAgo}</Typography>
                        <Box>
                          <Typography sx={{color: grayColor[0]}} variant={'caption'}>
                            Przez:{' '}</Typography>
                          <Typography sx={{color: primaryColor[2]}} variant={'caption'}>
                            {updatedBy}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell width={200} align="left">
                        <Typography sx={{color: primaryColor[0]}} fontWeight={'bold'}
                          // color={type === INCIDENT ? warningColor[0] : (type === UNKNOWN ? grayColor[1] : successColor[0])}
                        >
                          {t('maintenance.typeLabels', {returnObjects: true})[type]}
                        </Typography>
                        <br/>
                        <Typography variant={'caption'} color={grayColor[0]}>
                          {t('maintenance.typeLabelsDesc', {returnObjects: true})[type]}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <DurationStats status={status} createdAt={createdAt} updatedAt={updatedAt}
                                       language={i18n.resolvedLanguage}/>
                      </TableCell>

                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer></Box>}
        </CardContent>
      </Card>
    </Box>
  </>;
};

const MaintenancePhoto = ({photoObj, idx}) => {
  const {createdBy, lat, lon, timestamp, url} = photoObj;
  const [expanded, setExpanded] = useState(false);

  const togglePhoto = () => setExpanded(!expanded);

  return <Box display={'flex'} flexDirection={'column'}>
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      onClick={togglePhoto}
      sx={{
        position: 'relative',
        width: expanded ? '80%' : 100,
        height: expanded ? '80%' : 100,
        cursor: expanded ? 'zoom-out' : 'pointer',
        overflow: expanded ? 'visible' : 'hidden',
      }}>

      <Typography variant={'caption'}
                  sx={{marginLeft: 1, color: grayColor[1]}}>{idx === 0 ? 'Przed' : 'Po'}</Typography>

      <a target={'_blank'} href={`http://www.google.com/maps/place/${lat},${lon}`}>
        <LocationOn sx={{color: primaryColor[2], right: 0, top: -2, position: 'absolute'}}/>
      </a>
      <img alt={'device Photo'}
           style={{
             height: '100%',
             width: '100%',
             borderRadius: 5,
             border: ' 1px solid whitesmoke',
           }}
           src={url}
      />

    </Box>
    <Typography sx={{color: grayColor[0]}} variant={'caption'}>
      {moment(timestamp).format('lll')}
    </Typography>
    <Typography sx={{color: primaryColor[2]}} variant={'caption'}>
      {createdBy}
    </Typography>
  </Box>;
};

const PhotosCell = ({photos}) => {
  return <Box display={'flex'} flexWrap={'wrap'} gap={3}>
    {photos.map((p, i) => <MaintenancePhoto photoObj={p} idx={i} key={i}/>)}
  </Box>;
};

const DescriptionCell = ({status, description, notes, t}) => {
  const [expand, setExpand] = useState(false);
  const {createdAt, createdBy, text} = description || {};

  return <Box display={'flex'} flexWrap={'wrap'} gap={1} flexDirection={'column'} alignItems={'flex-start'}
              justifyContent={'flex-start'}>
    <Typography fontWeight={'bold'}
                color={status === NEW ? warningColor[0] : (status === DONE ? grayColor[1] : successColor[0])}>
      {t('maintenance.statusLabels', {returnObjects: true})[status]}
    </Typography>
    {text && <>
      <Typography sx={{width: '100%', color: grayColor[1]}}>
        {text}
      </Typography>
      <Box>
        <Typography sx={{color: grayColor[0]}} variant={'caption'}>
          Dodana: {moment(createdAt).format('lll')},
        </Typography>
        <Typography sx={{marginLeft: 1, color: grayColor[0]}} variant={'caption'}>
          Przez:{' '}</Typography>
        <Typography sx={{color: primaryColor[2]}} variant={'caption'}>
          {createdBy}
        </Typography>
      </Box>

    </>}

    {notes && <Box width={'100%'}>
      <Button
        sx={{textDecoration: !expand ? 'underline' : 'none', mb: 2}}
        // size={'small'}
        variant={'text'}
        onClick={() => setExpand(!expand)}>
        <Typography variant={'button'} color={primaryColor[2]}>Notatki ({notes.length}):</Typography>
        {expand ? <ExpandLess/> : <ExpandMore/>}
      </Button>

      {expand && (notes || []).map(({createdAt, createdBy, text}, idx) =>
        <Box width={'100%'} borderBottom={'1px solid ' + grayColor[3]} mb={3}>
          <Typography variant={'caption'} color={primaryColor[2]}>Nr {idx + 1}.</Typography>
          <Typography color={grayColor[1]}>{text}</Typography>
          <Box display={'flex'} gap={1}>
            <Typography color={grayColor[1]} fontSize={10}
                        variant={'caption'}>Dodana: {createdAt.slice(0, 16).replace('T', ', ')}</Typography>
            <Typography color={grayColor[1]} fontSize={10} variant={'caption'}>Przez: {createdBy}</Typography>
          </Box>
        </Box>)}
      {expand && <Button onClick={() => setExpand(false)}>Zwiń Notatki<ExpandLess/></Button>}

    </Box>}
  </Box>;
};


const DurationStats = ({status, createdAt, reactedAt, updatedAt, language}) => {
  if (status === NEW) {
    return <Box>
      <Typography color={grayColor[1]}>
        Nie podjęto jeszcze akcji.
      </Typography>
      <Typography color={grayColor[1]}>
        Zgłoszono: <Typography fontWeight={'bold'}>{moment(createdAt).fromNow()}</Typography>
      </Typography>
    </Box>;
  } else if (status === STARTED) {
    return <Box gap={1}>
      <Box display={'flex'} gap={1}>
        <Typography color={grayColor[1]}>
          Reakcja:
        </Typography>
        <Duration timedelta={moment(reactedAt || updatedAt) - moment(createdAt)} language={language}/>
      </Box>
      <Box display={'flex'} gap={1}>
        <Typography color={grayColor[1]}>
          Serwis:
        </Typography>
        <Duration timedelta={updatedAt - moment(reactedAt || updatedAt)} language={language}/>
      </Box>
    </Box>;
  } else {
    return <Box gap={1}>
      <Box display={'flex'} gap={1}>
        <Typography color={grayColor[1]}>
          Reakcja: {' '}
        </Typography>
        <Duration timedelta={moment(reactedAt || updatedAt) - moment(createdAt)} language={language}/>
      </Box>
      <Box display={'flex'} gap={1}>
        <Typography color={grayColor[1]}>
          Serwis:
        </Typography>
        <Duration timedelta={updatedAt - moment(reactedAt || updatedAt)} language={language}/>
      </Box>
      {/*<Box display={'flex'} gap={1}>*/}
      {/*  <Typography color={grayColor[1]}>*/}
      {/*    Niedostępność:*/}
      {/*  </Typography>*/}
      {/*  <Duration timedelta={updatedAt - createdAt} language={language}/>*/}
      {/*</Box>*/}
    </Box>;
  }
};

const Duration = ({timedelta, language}) => <Typography color={grayColor[1]}
                                                        fontWeight={'bold'}>{humanizeDuration(timedelta, {
  units: ['d', 'h', 'm'],
  largest: 2,
  round: true,
  language
})}</Typography>;


const iconsByDevice = (device) => {
  const iconsList = [];
  if (device.type === 'water' || device.type === 'buoy') {
    iconsList.push(<Water sx={{backgroundColor: 'white', borderRadius: 1}} color={'info'}/>);
  }
  if (device.type === 'air') {
    iconsList.push(<BubbleChartOutlined sx={{backgroundColor: 'white', borderRadius: 1}} color={'primary'}/>);
  }
  if (device.serialNo.includes('WEATHER')) {
    iconsList.push(<Cloud sx={{backgroundColor: 'white', borderRadius: 1}} color={'success'}/>);
  }
  if (device.camera) {
    iconsList.push(
      <img style={{marginLeft: 10, borderRadius: 5}} height={'25px'} src={WaterCamera} alt={'Camera on Water'}/>
    );
  }

  return <Box ml={2} mt={2} sx={{textAlign: 'left'}}>
    {iconsList}
  </Box>;
};
