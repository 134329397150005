import React, {useState} from 'react';
import Highcharts from 'highcharts/highstock';
import {
  Chart,
  HighchartsProvider,
  HighchartsStockChart,
  Legend,
  LineSeries,
  SplineSeries,
  Title,
  Tooltip,
  XAxis,
  YAxis,
} from 'react-jsx-highstock';

import applyExporting from 'highcharts/modules/exporting';
import applyOffline from 'highcharts/modules/offline-exporting';

import {getChartMeasurements, getMockedWaterChartMeasurements} from '../../api';
import Box from '@mui/material/Box';
import AirDateRangePicker from './AirDateRangePicker';
import QuickAirDateButtons from './QuickAirDateButtons';
import {
  dangerColor,
  infoColor,
  MOBILE_SCREEN,
  primaryColor,
  successColor
} from '../../assets/jss/nextjs-material-dashboard';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import {useMediaQuery} from "@mui/material";
import DeviceImage from './DeviceImage';
import {useTranslation} from 'react-i18next';


applyExporting(Highcharts);
applyOffline(Highcharts);

const NUMBER_OF_BUCKETS = 70;

const plotOptions = {
  column: {
    pointWidth: 6,
    maxPointWidth: 50,
    minPointLength: 3, // By default, 0 values are not shown. To visualize a 0 (or close to zero) point, set the minimal point length to a pixel value like 3
    borderWidth: 0
  },
  series: {
    shadow: true,
    // dataGrouping: {
    //   approximation: 'average',
    //   groupAll: true,
    //   groupPixelWidth: 20
    // }
  }
};

const plotHorizontalNoiseLines = [{
  zIndex: 99,
  value: 65,
  color: 'red',
  dashStyle: 'shortdash',
  width: 2
}];

const timeOptions = {
  useUTC: false
};

const options = {};

// Conductivity of Water - TYPICAL RANGES
const drinkWater = [];
// [{
//   zIndex: 99,
//   value: 1000,
//   color: 'lightblue',
//   dashStyle: 'shortdash',
//   width: 2
// }];

const WATER_MEASUREMENTS = "temp,chlorophyll,ph,ec,turbidity,o2,orp,do,no3,oil";

// MUST READ -> https://www.fao.org/3/ac183e/AC183E00.htm#TOC
export default function WaterChart({device}) {
  const {t} = useTranslation();
  const {clientId} = useConfig().state.config;
  const title = device.name;

  // TODO implement this
  // const [cache, setCache] = useState({});

  const afterSetExtremes = (e) => {
    const {serialNo} = device;

    const {chart} = e.target;
    const nowMillis = new Date().getTime();

    const min = e.userMin || (nowMillis - 1000 * 60 * 60 * 24);
    const max = e.userMax || nowMillis;

    var rangeInHours = null;

    const dateFromHours = rangeInHours ? rangeInHours : Math.max(1, Math.round((nowMillis - min) / (1000 * 60 * 60)));
    const dateToHours = rangeInHours ? 0 : Math.max(0, Math.round((nowMillis - max) / (1000 * 60 * 60)));
    const dateTimeFrom = '-' + dateFromHours + 'h';
    const dateTimeTo = '-' + dateToHours + 'h';
    chart.showLoading(t('general.loading'));
    (['ugw', 'atm', 'mbm'].includes(clientId) ? getChartMeasurements(clientId, serialNo, device.measurements, dateTimeFrom, dateTimeTo, NUMBER_OF_BUCKETS) :
      getMockedWaterChartMeasurements(clientId, serialNo, WATER_MEASUREMENTS, dateTimeFrom, dateTimeTo, NUMBER_OF_BUCKETS))
      .then(data => {
        if (data[serialNo]) {
          let i = 0;
          if (device.measurements.includes('temp')) {
            chart.series[i].setData(data[serialNo].temp);
            i++;
          }
          if (device.measurements.includes('chlorophyll')) {
            chart.series[i].setData(data[serialNo].chlorophyll);
            i++;
          }
          if (device.measurements.includes('ph')) {
            chart.series[i].setData(data[serialNo].ph);
            i++;
          }
          if (device.measurements.includes('ec')) {
            chart.series[i].setData(data[serialNo].ec);
            i++;
          }
          if (device.measurements.includes('turbidity')) {
            chart.series[i].setData(data[serialNo].turbidity);
            i++;
          }
          if (device.measurements.includes('orp')) {
            chart.series[i].setData(data[serialNo].orp);
            i++;
          }
          if (device.measurements.includes('do')) {
            chart.series[i].setData(data[serialNo].do);
            i++;
          }
          if (device.measurements.includes('o2')) {
            chart.series[i].setData(data[serialNo].o2);
            i++;
          }
          if (device.measurements.includes('no3')) {
            chart.series[i].setData(data[serialNo].no3);
            i++;
          }
        }
        chart.hideLoading();
      }).catch(error => console.error(error.message));
  };

  const [xAxisEvents, setXAxisEvents] = useState({afterSetExtremes: afterSetExtremes});
  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);

  return (<Box position={'relative'}>
      <HighchartsProvider Highcharts={Highcharts}>
        <HighchartsStockChart options={options} plotOptions={plotOptions} time={timeOptions}>
          <Chart zooming={{type: 'x'}} panning={{enabled: true}} panKey={'shift'} />
          <Legend align={'left'} verticalAlign={'top'} x={40} y={30} useHTML={true}/>
          <Title style={{color: primaryColor[2]}} align={'center'}>{title}</Title>

          <Tooltip split valueDecimals={2}/>
          <XAxis
            events={xAxisEvents}
            range={15 * 24 * 60 * 60 * 1000}
          />

          {/* TEMP LINE CHART */}
          {device.measurements.includes('temp') && <YAxis
            min={0}
            max={40}
            opposite={false}
            // plotLines={plotHorizontalNoiseLines}
            useHTML={true}>
            <YAxis.Title>Temp °C</YAxis.Title>

            <LineSeries
              id="temp"
              name={'Temp'}
              color={dangerColor[2]}
              tooltip={{valueSuffix: ' [°C]'}}
              zones={[{value: 15, color: 'blue'}, {color: 'red'}]}
            />
          </YAxis>}

          {/* CHLOROPHYLL LINE CHART */}
          {device.measurements.includes('chlorophyll') && <YAxis
            min={0}
            max={100}
            opposite={true}
            useHTML={true}>
            <YAxis.Title>Chlorofil ug/L</YAxis.Title>

            <LineSeries
              id="chlorophyll"
              name={t('measurements.parameters.chlorophyll')}
              color={successColor[2]}
              tooltip={{valueSuffix: ' [ug/L]'}}
              // zones={[{value: 15, color: 'blue'}, {color: 'red'}]}
            />
          </YAxis>}

          {/* PH LINE CHART
          TODO COLORS: https://www.jansanconsulting.com/ph-scale.html
          */}
          {device.measurements.includes('ph') && <YAxis
            labels={{enabled: false}}
            min={0}
            max={14}
            opposite={true}
            useHTML={true}>
            {/*<YAxis.Title>pH </YAxis.Title>*/}
            <SplineSeries
              visible={true}
              id="ph"
              name={'Ph'}
              tooltip={{valueSuffix: ''}}
              zones={WATER_PH_LEVELS}
              color={'green'}/>
          </YAxis>}

          {/* CONDUCTIVITY / SALINITY / TDS - LINE CHART*/}
          {device.measurements.includes('ec') && <YAxis
            // min={0}
            // max={4000}
            opposite={true}
            labels={{enabled: false}}
            plotLines={drinkWater}
            useHTML={true}>
            {/*<YAxis.Title>Conductivity [mS]</YAxis.Title>*/}
            <SplineSeries
              visible={true}
              id="ec"
              name={t('measurements.parameters.ec')}
              color={infoColor[1]}
              dashStyle={'ShortDot'}
              tooltip={{
                valueSuffix: ' [µS/cm]',
                valueDecimals: 2
              }}
              zones={WATER_PH_LEVELS}
            />
          </YAxis>}

          {/* TURBIDITY */}
          {device.measurements.includes('turbidity') && <YAxis useHTML={true} labels={{enabled: false}}>
            <LineSeries
              visible={true}
              id="turbidity"
              name={t('measurements.parameters.turbidity')}
              color={primaryColor[1]}
              dashStyle={'LongDashDot'}
              tooltip={{
                valueSuffix: ' [NTU]',
                valueDecimals: 0
              }}
            />
          </YAxis>}

          {/* ORP */}
          {device.measurements.includes('orp') && <YAxis useHTML={true} labels={{enabled: false}}>
            <LineSeries
              visible={false}
              id="orp"
              name={t('measurements.parameters.orp')}
              color={'gray'}
              opacity={0.5}
              dashStyle={'Dot'}
              tooltip={{
                valueSuffix: ' [mV]',
                valueDecimals: 0
              }}
            />
          </YAxis>}

          {/* Dissolved Oxygen */}
          {device.measurements.includes('do') && <YAxis useHTML={true} labels={{enabled: false}}>
            {/*<YAxis.Title>Dissolved Oxygen [mg/L]</YAxis.Title>*/}
            <LineSeries
              id="do"
              name={t('measurements.parameters.do')}
              color={'#8F00FF'}
              dashStyle={'LongDash'}
              tooltip={{
                valueSuffix: ' [mg/L]'
              }}
            />
          </YAxis>}

          {/* Saturation O2 */}
          {device.measurements.includes('o2') && <YAxis useHTML={true}>
            <YAxis.Title>{t('measurements.parameters.o2') + '[%]'}</YAxis.Title>
            <LineSeries
              id="o2"
              name={t('measurements.parameters.o2') + '[%]'}
              color={'#f99bff'}
              dashStyle={'LongDash'}
              tooltip={{
                valueSuffix: ' [%]'
              }}
            />
          </YAxis>}

          {/* NO3 */}
          {device.measurements.includes('no3') && <YAxis useHTML={true} labels={{enabled: false}}>
            <LineSeries
              visible={true}
              id="turbidity"
              name={t('measurements.parameters.no3')}
              // color={primaryColor[1]}
              // dashStyle={'LongDashDot'}
              tooltip={{
                valueSuffix: ' [mg/L]',
                valueDecimals: 0
              }}
            />
          </YAxis>}

          <QuickAirDateButtons width={!isMobile ? 200 : "100%"}/>
          <AirDateRangePicker/>

          <DeviceImage clientId={clientId} serialNo={device.serialNo} />

        </HighchartsStockChart>
      </HighchartsProvider>
    </Box>
  );
}

const WATER_PH_LEVELS = [
  {
    value: 1,
    color: '#da3b33'
  }, {
    value: 2,
    color: '#f0c846'
  }, {
    value: 3,
    color: '#f4ee54'
  }, {
    value: 4,
    color: '#bbd252'
  }, {
    value: 5,
    color: '#92c055'
  }, {
    value: 6,
    color: '#6bb558'
  }, {
    value: 7,
    color: '#57a656'
  }, {
    value: 8,
    color: '#57b272'
  }, {
    value: 9,
    color: '#52b1b3'
  }, {
    value: 10,
    color: '#598fc8'
  }, {
    value: 11,
    color: '#3e539f'
  }, {
    value: 12,
    color: '#59519d'
  }, {
    value: 13,
    color: '#5e4798'
  }, {
    value: 14,
    color: '#422d7f'
  }
];
