import {Box, Card, CardContent, CardHeader, Checkbox, FormControlLabel, Modal, Switch, Tooltip} from '@mui/material';
import Typography from '@mui/material/Typography';
import {CalendarMonth, CameraOutlined, ChevronLeft, Close, PlayArrow} from '@mui/icons-material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import CircularProgress from '@mui/material/CircularProgress';
import {executeCameraDeviceImage, getCameraDeviceImage, getCameraDeviceImages} from '../../api';
import moment from 'moment';
import {LoadingButton} from '@mui/lab';
import './swal_custom_style.css';
import Button from '@mui/material/Button';
import DatePicker from 'react-datepicker';
import {useCameraContext} from '../../context/camera/cameraContext';
import {grayColor} from '../../assets/jss/nextjs-material-dashboard';
import PhotosView from './PhotosView';
import ReplayView from './ReplayView';

// TODO add some information to the device
const WAIT_FOR_IR = "ZPG_OAKD_TEST";

export default function CameraDeviceModal({cameraStation, onClose}) {
  const {t, i18n} = useTranslation();
  const {clientId} = useConfig().state.config;
  const {state: cameraState, dispatch: cameraDispatch} = useCameraContext();
  const {images, filter, selectedDate, showAll} = cameraState;
  const [loading, setLoading] = useState(true);
  const [cameraInProgress, setCameraInProgress] = useState(false);
  const [awaitingImage, setAwaitingImage] = useState();
  const {name, serialNo} = cameraStation;
  const [selectedView, setSelectedView] = useState('photos');

  const setImages = images => cameraDispatch({type: 'set-images', images});
  const toggleShowAll = () => cameraDispatch({type: 'toggle-showAll'});

  const onDateChange = date => cameraDispatch({type: 'set-selectedDate', selectedDate: new Date(date)});

  // Toggle view between photos and imagePlayer component
  const toggleView = () => setSelectedView(selectedView === 'photos' ? 'imagePlayer' : 'photos');


  moment.locale(i18n.language);

  const onChangeFilter = type => {
    if (filter.includes(type)) {
      cameraDispatch({type: 'set-filter', filter: filter.filter(e => e !== type)});
    } else {
      cameraDispatch({type: 'set-filter', filter: [type, ...filter]});
    }
  };

  const filteredImages = images.filter(imgItem => {
    const isInFilter = filter.includes(imgItem.type);
    const imgDate = new Date(imgItem.timestamp);
    return isInFilter && (showAll || moment(selectedDate).isSame(imgDate, 'day'));
  })

  // Fetch new images for device
  useEffect(() => {
    setLoading(true);
    getCameraDeviceImages(clientId, serialNo)
      .then(result => setImages(result))
      .finally(() => setLoading(false));
  }, [clientId, serialNo]);

  const takePhoto = () => {
    setCameraInProgress(true);
    executeCameraDeviceImage(clientId, serialNo).then(result => setAwaitingImage({clientId, ...result}));
  };

  useEffect(() => {
    if (!awaitingImage) return;

    const intervalId = setInterval(() => {
      getCameraDeviceImage(awaitingImage.clientId, awaitingImage.serialNo, awaitingImage.timestamp)
        .then(result => {
          if (result.length > 0) {
            if (WAIT_FOR_IR === serialNo) {
              if(result[0].imgUrlIR) {
                setImages([result[0], ...images]);
                setAwaitingImage(null);
                setCameraInProgress(false);
                clearInterval(intervalId);
              }
            } else {
              setImages([result[0], ...images]);
              setAwaitingImage(null);
              setCameraInProgress(false);
              clearInterval(intervalId);
            }
          }
        })
        .catch(err => clearInterval(intervalId));
    }, 5000); // 5000 ms = 5 sekund
    return () => clearInterval(intervalId);
  }, [awaitingImage, images]);

  // TITLE: Name, DatePicker, showAll switch
  const titleComponent = <Box display={'flex'} gap={5} justifyContent={'center'} alignItems={'center'}>
    <Box>
      <Typography color={'primary'} variant={'h5'}>
        {name}
      </Typography>
    </Box>
    <Box>
      {showAll ? <Button variant={'text'} endIcon={<CalendarMonth/>}>
        {moment(new Date()).subtract(7, 'days').format('DD - ') +
          moment(new Date()).format('DD MMMM YYYY')}
      </Button> : <DatePicker
        customInput={
          <Button variant={'outlined'} endIcon={<CalendarMonth/>}>
            {moment(selectedDate).format('DD MMMM YYYY')}
          </Button>
        }
        locale={i18n.resolvedLanguage}
        onChange={onDateChange}
        selected={selectedDate}
        maxDate={new Date()}
        minDate={moment('2024-05-01').toDate()}
      />}
    </Box>

    <Box>
        <FormControlLabel
          control={<Checkbox checked={showAll} onChange={toggleShowAll} size={'small'} />}
          label={<Typography color={grayColor[2]} variant={'caption'}>{t('showAll')} ({images.length})</Typography>}
        />
    </Box>
  </Box>;

  const photosSubheader = <Box mt={3} display={'flex'} gap={2} alignItems={'center'} justifyContent={'space-between'}>
    <Box>
      <LoadingButton
        loading={cameraInProgress}
        variant={'contained'}
        startIcon={<CameraOutlined/>}
        onClick={takePhoto}
      >
        {t('cameraDevicePhoto.takeAPicture')}
      </LoadingButton>
      {cameraInProgress && <Typography variant={'caption'} sx={{marginLeft: 2}}>
        {t('cameraDevicePhoto.thePhotoIsBeingTakenPleaseWaitThisMayTakeAbout10Seconds')}
      </Typography>}

      {!cameraInProgress && !loading && <Button
        sx={{marginLeft: 2}}
        color={'secondary'}
        variant={'contained'}
        startIcon={<PlayArrow sx={{color: 'white'}}/>}
        onClick={toggleView}
      >
        <Typography variant={'button'} color={'white'}>Play</Typography>
      </Button>}
    </Box>

    <Box mr={'5%'} display={'flex'} gap={1}>
      <Tooltip title={t('cameraDevicePhoto.photosTakenAtEachMeasurementAreAutomaticallyDeletedAfter7Days')}>
        <FormControlLabel
          checked={filter.includes('auto')}
          onChange={() => onChangeFilter('auto')}
          control={<Switch defaultChecked/>}
          label={<Typography variant={'button'}
                             textTransform={'capitalize'}>{t('cameraDevicePhoto.automatic')}</Typography>}
          labelPlacement="left"
        />
      </Tooltip>
      <Tooltip title={t('cameraDevicePhoto.photosAssociatedWithExceedingStandards')}>
        <FormControlLabel
          checked={filter.includes('episode')}
          onChange={() => onChangeFilter('episode')}
          control={<Switch defaultChecked color="error"/>}
          label={<Typography variant={'button'}
                             textTransform={'capitalize'}>{t('cameraDevicePhoto.violations')}</Typography>}
          labelPlacement="left"
        />
      </Tooltip>
      <Tooltip title={t('cameraDevicePhoto.photosCommissionedByTheTakeAPhotoButton')}>
        <FormControlLabel
          checked={filter.includes('custom')}
          onChange={() => onChangeFilter('custom')}
          control={<Switch defaultChecked color="success"/>}
          label={<Typography variant={'button'} textTransform={'capitalize'}><i>Ad hoc</i></Typography>}
          labelPlacement="left"
        />
      </Tooltip>
    </Box>
  </Box>;

  const replaySubheader = <Box mt={-4} mb={2} display={'flex'} >
    <Button
      sx={{marginLeft: 2}}
      color={'primary'}
      variant={'text'}
      startIcon={<ChevronLeft/>}
      onClick={toggleView}
    >
      <Typography variant={'button'}>Wróć do zdjęć</Typography>
    </Button>
  </Box>;

  return <Modal open={true} sx={{ overflowY: 'scroll'}}>
    <Card elevation={0} sx={{p: 2, minHeight: '100vh'}}>
      <CardHeader
        action={<Close sx={{cursor: 'pointer'}} color={'error'} onClick={onClose}/>}
        title={titleComponent}
        subheader={selectedView === 'photos' ? photosSubheader : replaySubheader}
      />
      <CardContent>
        {loading ?
          <Box>
            <CircularProgress size={100} sx={{marginLeft: 'calc(50% - 50px)', marginTop: 10}}/>
          </Box>
          :
          (selectedView === 'photos' ? <PhotosView images={filteredImages} device={cameraStation}/> : <ReplayView images={filteredImages}/>
          )}
      </CardContent>
    </Card>
  </Modal>;
}
