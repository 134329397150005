import React, {useMemo, useReducer} from 'react';
import PropTypes from 'prop-types';
import {NEW} from '../../views/MaintenanceStatus';
import {SERVICE, UNKNOWN} from '../../views/MaintenanceType';
import moment from 'moment';

export const initialMaintenanceObj = (clientId, email, serialNo) => ({
  clientId,
  serialNo: serialNo,
  status: NEW,
  type: UNKNOWN,
  createdAt: moment().valueOf(),
  createdBy: email,
  updatedAt: moment().valueOf(),
  updatedBy: email,
  initialText: '',
});
const initialState = () => {
  return {
    allMaintenances: {},
    activeMaintenances: {},
    selectedMaintenance: null
  };
};


const MaintenanceContext = React.createContext(initialState());
// expose the context via a custom hook.
export const useMaintenance = () => React.useContext(MaintenanceContext);

function reducer(state, action) {
  switch (action.type) {
  case 'set-activeMaintenances':
    return {
      ...state,
      activeMaintenances: action.activeMaintenances,
    };

  case 'set-allMaintenances':
    return {
      ...state,
      allMaintenances: action.allMaintenances,
    };
  case 'set-selectedMaintenance':
    return {
      ...state,
      selectedMaintenance: action.selectedMaintenance,
    };
  default:
    throw new Error('REDUCER cannot handle action ' + action.type);
  }
}

export default function MaintenanceContextProvider({children}) {
  const [state, dispatch] = useReducer(reducer, initialState());
  const maintenanceContext = useMemo(() => {
    return {
      state,
      dispatch,
    };
  }, [state]);

  return <MaintenanceContext.Provider value={maintenanceContext}>
    {children}
  </MaintenanceContext.Provider>;
}

MaintenanceContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};
