import {Card, CardContent, CardHeader, Typography, useMediaQuery} from '@mui/material';
import Button from '@mui/material/Button';
import {FilterDrama, Refresh} from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import React, {useEffect, useState} from 'react';
import {MOBILE_SCREEN, primaryColor} from '../../assets/jss/nextjs-material-dashboard';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import {useTranslation} from 'react-i18next';
import Meteogram from '../Meteogram/Meteogram';
import moment from 'moment';
import {getForecast, getWeather} from '../../api';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import pl from 'date-fns/locale/pl';
import fr from 'date-fns/locale/fr';
import MeteoPLSlider from '../MeteoPLSlider/MeteoPLSlider';
import OpenDataQuickDateButtons from '../../views/OpenData/OpenDataQuickDateButtons';
import DateRangePicker from '../DateRangePicker/DateRangePicker';
import {addDays} from 'date-fns';
import WeatherStationChart from '../WeatherStationChart/WeatherStationChart';

registerLocale('es', es);
registerLocale('pl', pl);
registerLocale('fr', fr);


export default function WeatherCharts() {
  const {t, i18n} = useTranslation();
  const {config: {clientId, meteogram, lang, weatherStations}} = useConfig().state;
  const [weatherData, setWeatherData] = useState();
  const [forecastData, setForecastData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());

  //  NEW STUFF
  const [dateFrom, setDateFrom] = useState(addDays(new Date(), -1));
  const [dateTo, setDateTo] = useState(new Date());
  const [quickDays, setQuickDays] = useState(1);
  const setDateRange = (from, to) => {
    // console.log({from, to, days: moment(to).diff(moment(from), 'days')});
    setDateFrom(from);
    setDateTo(to);
    setQuickDays(moment(to).diff(moment(from), 'days'));
  };

  const setQuickDateRange = days => {
    const now = new Date();
    const start = addDays(now, -days);
    setDateRange(start, now);
    // fetchWeatherData();
  };


  const isMobile = useMediaQuery(`(max-width:${MOBILE_SCREEN})`);

  // set locale for datepicker
  useEffect(() => setDefaultLocale(i18n.language), [i18n.language]);

  useEffect(async () => {
    setLoading(true);
    const weatherDate = moment(selectedDate).format('YYYY-MM-DD');
    const forecastDate = moment(selectedDate).subtract(1, 'days').format('YYYY-MM-DD');
    try {
      const weatherData = await getWeather(clientId, weatherDate);
      setWeatherData(weatherData);
      const forecastData = await getForecast(clientId, forecastDate);
      setForecastData(forecastData);
    } catch (e) {
      setError(e.toString());
    } finally {
      setLoading(false);
    }
  }, [selectedDate]);

  const dateChanged = date => {
    setSelectedDate(date);
  };

  return <>
    <Card variant={'outlined'} sx={{minHeight: '100%'}}>
      {!isMobile && <CardHeader
        title={<Button sx={{'@media screen and (max-width: 50em)': {display: 'none'}}}
                       size={'large'} startIcon={<FilterDrama/>}
                       color={'info'}>{t('weather.weather')}</Button>}
      />}


      <CardContent>
        {error && <Typography color={'error'} sx={{marginBottom: 1}}>{error}</Typography>}

        {loading ? <CircularProgress size={100} sx={{marginLeft: '40%', marginTop: 10}}/> :
          <Box textAlign={'center'} position={'relative'}>
            <Box sx={{
              position: 'absolute',
              right: 10,
              zIndex: 100,
            }}>
              <Box sx={{
                position: 'absolute',
                '@media screen and (min-width: 20em)': {top: '2.5em', right: '-.2em'},
                '@media screen and (min-width: 22em)': {top: '2.5em', right: '-.7em'},
                '@media screen and (min-width: 30em)': {top: '2.5em', right: '2em'},
                '@media screen and (min-width: 50em)': {top: '2.5em', right: '2em'},
                '@media screen and (min-width: 55em)': {top: '2em', right: '2em'},
                '@media screen and (min-width: 65em)': {top: '2em', right: '2em'},
              }
              }>
                <DatePicker
                  filterDate={(date) => {
                    return moment() > date;
                  }}
                  selected={selectedDate}
                  onChange={dateChanged}
                />
              </Box>

            </Box>

            {weatherStations && weatherStations.map(weatherStation =>
              <WeatherStationChart device={weatherStation}
                                   selectedDate={selectedDate}/>)}

            {forecastData && <Box>
              <Typography variant={'h6'}
                          sx={{'@media screen and (max-width: 50em)': {fontSize: '1rem'}}}
                          style={{marginTop: isMobile ? 0 : 40, color: primaryColor[2]}}>
                {t('weather.forecast')} {moment(selectedDate).format('YYYY-MM-DD')}
              </Typography>
              <Meteogram data={forecastData}/>
            </Box>}

            <Typography variant={'h6'}
                        sx={{'@media screen and (max-width: 50em)': {fontSize: '1rem'}}}
                        style={{paddingTop: 20, marginBottom: -7, color: primaryColor[2]}}>
              {t('weather.past')} {moment(selectedDate).format('YYYY-MM-DD')}
            </Typography>
            {weatherData && <Box>
              <Meteogram data={weatherData}/>
            </Box>}

            <Box mt={5} display={'flex'} width={'100%'}>

              {/* DateRangePicker */}
              <Box sx={{width: 300}}>
                <DateRangePicker
                  inputStyle={'mui'}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                  buttonColor={'white'}
                  onChange={setDateRange}/>
              </Box>

              <Box
                width={230}
                position={'relative'}
              >
                <Typography sx={{position: 'absolute', top: -20}} color={'rgba(0, 0, 0, 0.6)'} variant={'caption'}>
                  {t('openData.quickDateSelect')}
                </Typography>
                <OpenDataQuickDateButtons days={quickDays} onClick={setQuickDateRange}/>
              </Box>

              {/* ACTION BUTTON */}
              <Box>
                <Button
                  sx={{width: 50}}
                  // onClick={fetchWeatherData}
                  // disabled={measurements.length === 0}
                  color={'success'}
                  variant={'contained'}>
                  {/*{t('openData.fetchData')}*/}
                  <Refresh/>
                </Button>
              </Box>
            </Box>
            <MeteoPLSlider dateFrom={moment(dateFrom)} dateTo={moment(dateTo)} row={meteogram.row} col={meteogram.col}
                           lang={lang}/>
          </Box>
        }
      </CardContent>
    </Card>
  </>;
}
