import {
  dangerColor,
  grayColor,
  infoColor,
  primaryColor,
  successColor,
  warningColor
} from '../assets/jss/nextjs-material-dashboard';
import {getColorBetweenGreenAndRed} from '../utils';
import CustomLinearProgress from '../views/Map/CustomLinearProgress';
import React from 'react';
import {
  Air,
  EnergySavingsLeaf,
  Grass,
  Opacity,
  SolarPower,
  Speed,
  Terrain,
  Thermostat,
  Thunderstorm
} from '@mui/icons-material';
import TempSlider from './Sliders/TempSlider';
import PhSlider from './Sliders/PhSlider';
import OxygenSaturationSlider from './Sliders/OxygenSaturationSlider';
import DissolvedOxygenSlider from './Sliders/DissolvedOxygenSlider';
import OilInWaterSlider from './Sliders/OilInWaterSlider';
import ChlorophyllSlider from './Sliders/ChlorophyllSlider';

export const windDir = bearing => {
  if (348.75 < bearing || bearing <= 33.75)
    return 'N';
  if (33.75 < bearing && bearing <= 78.75)
    return 'NE';
  if (78.75 < bearing && bearing <= 123.75)
    return 'E';
  if (123.75 < bearing && bearing <= 168.75)
    return 'SE';
  if (168.75 < bearing && bearing <= 213.75)
    return 'S';
  if (213.75 < bearing && bearing <= 258.75)
    return 'SW';
  if (258.75 < bearing && bearing <= 303.75)
    return 'W';
  if (303.75 < bearing && bearing <= 348.75)
    return 'NW';
};


export const colorsBySerialNo = {
  'cindy': successColor[0],
  'pamela': grayColor[1],

  'ZPG_WATER_01': dangerColor[0],  // over 50
  'ZPG_WATER_02': warningColor[0],  // 15-30 ppm
  'ZPG_WATER_03': 'yellowgreen',
  'ZPG_WATER_04': successColor[0], // 0-15 ppm

  'ELB_01': warningColor[0],
  'CORAL_BUOY_01': warningColor[2],
  'REM_01': successColor[0],
  'REM_02': successColor[2],
  'REM_03': warningColor[0],
  'REM_04': dangerColor[0],
  'WATER_MOCK_01': successColor[2],
  'WATER_MOCK_02': warningColor[0],
  'WATER_MOCK_03': warningColor[1],
  'WATER_MOCK_04': dangerColor[0],
};

export const colorByPm10 = (pm10, pm100) => {
  if (pm100) {
    return primaryColor[2];
  }

  if (pm10 < 0) {
    return grayColor[0];
  }
  if (pm10 < 20) {
    return successColor[2];
  } else if (pm10 < 50) {
    return successColor[0];
  } else if (pm10 < 80) {
    return '#FFD911';
  } else if (pm10 < 110) {
    return warningColor[0];
  } else {
    return dangerColor[0];
  }
};

export const colorsByO2 = o2 => {
  if (o2 === null) {
    return grayColor[0];
  }

  if (o2 < 0) {
    return grayColor[0];
  }
  if (o2 < 60) {
    return dangerColor[0];
  } else if (o2 < 70) {
    return warningColor[0];
  } else if (o2 < 80) {
    return '#FFD911';
  } else if (o2 < 90) {
    return successColor[2];
  } else {
    return successColor[0];
  }
};

export const colorByPm25 = pm25 => {
  if (pm25 < 0) {
    return grayColor[0];
  }
  if (pm25 < 13) {
    return successColor[2];
  } else if (pm25 < 35) {
    return successColor[0];
  } else if (pm25 < 55) {
    return '#FFD911';
  } else if (pm25 < 75) {
    return warningColor[0];
  } else {
    return dangerColor[0];
  }
};

export const colorByNoise = noiseLvl => {
  if (noiseLvl <= 0) {
    return grayColor[0];
  }
  if (noiseLvl < 40) {
    return successColor[2];
  } else if (noiseLvl < 55) {
    return successColor[0];
  } else if (noiseLvl < 65) {
    return '#FFD911';
  } else if (noiseLvl < 85) {
    return warningColor[0];
  } else {
    return dangerColor[0];
  }
};

export const colorByOil = oilInWater => {
  if (oilInWater <= 0) {
    return grayColor[0];
  }
  if (oilInWater < 10) {
    return successColor[2];
  } else if (oilInWater < 25) {
    return successColor[0];
  } else if (oilInWater < 35) {
    return '#FFD911';
  } else if (oilInWater < 50) {
    return warningColor[0];
  } else {
    return dangerColor[0];
  }
};

export const colorBySoilSurfTemp = temp => {
  if (temp <= 0) {
    return grayColor[0];
  }
  if (temp < 5) {
    return warningColor[0]
    // return successColor[2];
  } else if (temp < 20) {
    return successColor[2];
  } else if (temp < 25) {
    return successColor[0];
  } else if (temp < 30) {
    return '#FFD911';
  } else if (temp < 33) {
    return warningColor[0];
  } else {
    return dangerColor[0];
  }
};

const WATER_PH_LEVELS = {
  1: '#da3b33',
  2: '#f0c846',
  3: '#f4ee54',
  4: '#bbd252',
  5: '#92c055',
  6: '#6bb558',
  7: '#57a656',
  8: '#57b272',
  9: '#52b1b3',
  10: '#598fc8',
  // 11: '#515d90',  //ciemniejszy odcień
  11: '#7c85b6',

  // 12: '#59519d',    //ciemniejszy odcień
  12: '#8c83c2',

  // 13: '#5e4798',
  13: '#8d75c2',

  // 14: '#422d7f' // Ciemny
  14: '#6b5baf'
};

export const colorByPh = ph => {
  return WATER_PH_LEVELS[Math.round(ph)] || 'gray';
};


export const colorsByMeasurements = {
  'PM10': colorByPm10,
  'PM25': colorByPm25,
  'PM1': colorByPm25,
  'noise': colorByNoise,
  'oil': colorByOil,
  'ph': colorByPh,
  'ec': (ec) => getColorBetweenGreenAndRed((ec / ThresholdByMeasurement['ec']) * 100, true),
  'orp': (orp) => getColorBetweenGreenAndRed((orp / ThresholdByMeasurement['orp']) * 100, true),
  'turbidity': (t) => getColorBetweenGreenAndRed((t / ThresholdByMeasurement['turbidity']) * 100, true),
  'no3': (t) => getColorBetweenGreenAndRed((t / ThresholdByMeasurement['no3']) * 100, true),
  // 'o2': (t) => getColorBetweenGreenAndRed(t, false),
  'o2': (t) => colorsByO2(t),

  'soilSurfTemp': colorBySoilSurfTemp,
  'airTemp': t => getColorBetweenGreenAndRed((t / ThresholdByMeasurement['airTemp']) * 100, false),
  'humidity': getColorBetweenGreenAndRed,
  'soilHumidity': getColorBetweenGreenAndRed,
  'soilTemp': t => getColorBetweenGreenAndRed((t / ThresholdByMeasurement['soilTemp']) * 100, false),
  'leafTemp': t => getColorBetweenGreenAndRed((t / ThresholdByMeasurement['leafTemp']) * 100, false),
  'leafWetnessYMSD': t => getColorBetweenGreenAndRed((t / ThresholdByMeasurement['leafWetnessYMSD']) * 100, false),
  'lightVisible': t => getColorBetweenGreenAndRed((t / ThresholdByMeasurement['lightVisible']) * 100, true),
  'windSpeedMax': t => getColorBetweenGreenAndRed((t / ThresholdByMeasurement['windSpeedMax']) * 100, false),
  'pressure': t => primaryColor[2],
  'rain': t => infoColor[2],
  'chlorophyll': t => getColorBetweenGreenAndRed((t / ThresholdByMeasurement['chlorophyll']) * 100, true),
  // 'chlorophyll': t => successColor[2],
};


export const ThresholdByMeasurement = {
  'chlorophyll': 400,
  'PM10': 110,
  'PM25': 75,
  'noise': 90,
  'oil': 100,
  'ph': 14,
  'ec': 1000,
  'orp': 300,
  'turbidity': 2000,
  'no3': 50,
  'o2': 100,

  'airTemp': 40,
  'humidity': 100,
  'soilHumidity': 100,
  'soilTemp':20,
  'soilSurfTemp': 35,
  'leafTemp': 30,
  'leafWetnessYMSD': 100,

  'lightVisible': 100,
  'windSpeedMax': 20
};

export const MeasurementsUnits = {
  'PM100': 'µg/m³',
  'chlorophyll': 'µg/L',
  'PM10': 'µg/m³',
  'PM25': 'µg/m³',
  'PM1': 'µg/m³',
  'noise': 'dBA',
  'oil': 'ppm',
  'do': 'mg/L',
  'o2': '%',
  'ph': ' ',
  'ec': 'µS/cm',
  'orp': 'mV',
  'turbidity': 'NTU',
  'temp': 'C°',
  'dhtHumidity': '%',
  'dhtTemp': 'C°',
  'interval': 'min',
  'signalStrengthLvl': ' ',

  'airTemp': 'C°',
  'windSpeedMax': 'm/s',
  'windDirectionMed': ' ',
  'pressure': 'hPa',
  'humidity': "%",
  'light': ' ',
  'lightVisible': 'lux',
  'soilHumidity': '%',
  'soilTemp': 'C°',
  'soilSurfTemp':'C°',
  'rain': 'mm',
  'leafWetnessYMSD': "%",
  'leafTemp': 'C°',

  'lightIr': '??',
  'lightUv': '??',
};

export const ValueSlider = ({measurementType: m, value}) => {
  return <CustomLinearProgress hidePercentage={['soilTemp', 'airTemp', 'soilSurfTemp', 'ph', 'o2'].includes(m)} value={value} min={0}
                               max={ThresholdByMeasurement[m]} color={colorsByMeasurements[m](value)}/>;
};

// TODO SLIDER
export const THRESHOLD_RANGE_INPUT = {
  'temp': (onChange, min, max) => <TempSlider min={min} max={max} onChange={onChange}/>,
  'chlorophyll': (onChange, min, max) => <ChlorophyllSlider min={min} max={max} onChange={onChange}/>,
  'ph': (onChange, min, max) => <PhSlider min={min} max={max} onChange={onChange}/>,
  'o2': (onChange, min, max) => <OxygenSaturationSlider min={min} max={max} onChange={onChange}/>,
  'do': (onChange, min, max) => <DissolvedOxygenSlider min={min} max={max} onChange={onChange}/>
};


export const AgroGroupIcons = {
  // airTemp: <Air />,
  soilHumidity: <Terrain />,
  soilTemp: <Terrain />,
  soilSurfTemp: <Terrain />,
  // leafTemp: <Nature />,
  leafTemp: <EnergySavingsLeaf />,
  leafWetnessYMSD: <EnergySavingsLeaf />,
}

export const AgroIcons = {
  windSpeedMax: <Air />,
  lightVisible: <SolarPower />,
  airTemp: <Thermostat />,
  pressure: <Speed />,
  humidity: <Opacity />,
  rain: <Thunderstorm />,
  soilHumidity: <Opacity />,
  soilTemp: <Thermostat />,
  soilSurfTemp: <Thermostat />,
  leafTemp: <Thermostat />,
  leafWetnessYMSD: <Opacity />,
}

export function AgroIcon(name, sx) {
  switch (name) {

  case 'windSpeedMax': return <Air sx={sx} />;
  case 'lightVisible': return <SolarPower sx={sx} />;
  case 'airTemp': return <Thermostat sx={sx} />;
  case 'pressure': return <Speed sx={sx} />;
  case 'humidity': return <Opacity sx={sx} />;
  case 'rain': return <Thunderstorm sx={sx} />;

  case 'soilHumidity':
    return <Opacity sx={sx}/>;
  case 'soilTemp':
    return <Thermostat sx={sx}/>;
  case 'soilSurfTemp':
    return <Thermostat sx={sx}/>;
  case 'leafTemp':
    return <Thermostat sx={sx}/>;
  case 'leafWetnessYMSD':
    return <Opacity sx={sx}/>;
  default:
    return <Grass sx={sx}/>;
  }
}


export const AGRO_PARAMS = [
  'soilHumidity',
  'soilTemp',
  'soilSurfTemp',
  'leafWetnessYMSD',
  'leafTemp'];


export const METEO_PARAMS = [
  // 'windDirectionMed',
  'windSpeedMax', // To jest wiodący atrybut dla Wiatru
  // 'wind',
  'airTemp',
  'pressure',
  'humidity',
  'lightVisible',  // To jest wiodący atrybut dla Light, reszta będzie dociągana
  // 'lightIr',
  // 'lightUv',
  // 'lightLux',
  // 'light',
  'rain'];

export const METEO_PARAMS_FULL = [
  'windDirectionMed',
  'windSpeed',
  'windSpeedMax',
  'airTemp',
  'pressure',
  'humidity',
  'lightVisible',
  'lightIr',
  'lightUv',
  'rain'];
