import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Auth} from 'aws-amplify';
import {useConfig} from 'context/clientConfig/clientConfigContext';
import {getClientConfig} from '../api';

const Authentication = ({children}) => {
  const [isCurrentAuthenticationChecked, setCurrentAuthenticationChecked] = useState(false);
  const {dispatch, state} = useConfig();

  useEffect( () => {
    Auth.currentSession().then(
      async () => {
        const user = await Auth.currentAuthenticatedUser();
        if (state?.email !== user.attributes.email) {
          const email = user.attributes.email;
          const clientId = email.includes("@seadata.pl") ? "zpg" : user.attributes['custom:clientId'];
          const roles = user.attributes['custom:role'];
          getClientConfig(clientId, roles).then(result => {
            if (clientId === "zpg" && (email?.includes('@seadata.pl') || email?.includes('camdrone') || email?.includes('mateuszblazejak'))) {
              result.devices = [...result.testDevices, ...result.devices];
            }
            dispatch({type: 'set-user', email, clientId, config: result});
            setCurrentAuthenticationChecked(true);
          }).catch(error => {
            console.log('error getting config in', {error});
          });
        }
      }
    )
      .catch((e) => {
        console.log('error', e);
        setCurrentAuthenticationChecked(true);
      });

  }, []);

  return isCurrentAuthenticationChecked ? children : <> </>;
};

Authentication.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Authentication;
