import {
  Chip,
  Switch,
  Table,
  TableContainer,
  TableFooter,
  TablePagination,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import React, {useEffect, useMemo, useState} from 'react';
import {
  dangerColor, DESKTOP_SCREEN,
  grayColor,
  MOBILE_SCREEN,
  primaryColor, TABLET_SCREEN,
  warningColor
} from '../../assets/jss/nextjs-material-dashboard';
import {useTranslation} from 'react-i18next';
import TableSortLabel from '@mui/material/TableSortLabel';
import {visuallyHidden} from '@mui/utils';
import {getComparator, stableSort, TablePaginationActions, THeader} from '../Table/tableUtills';
import {delEpisodesConfig, enableEpisodeConfig, getEpisodesConfig, saveEpisodeConfig} from '../../api';
import {useConfig} from '../../context/clientConfig/clientConfigContext';
import {ChevronLeft, DeleteSweep, Edit, ExpandLess, ExpandMore, NearMe} from '@mui/icons-material';
import _ from 'lodash';
import {formatPhoneNumber, openInNewTab, unitsByParams} from '../../utils';
import Button from '@mui/material/Button';
import MailiOS from 'assets/images/mail-ios.png';
import PhoneiOS from 'assets/images/phone-ios.png';
import {successColor} from '../PortManagement/DockStats/colors';
import Badge from '@mui/material/Badge';
import {useNotificationsContext} from '../../context/notifications/notificationsContext';
import Swal from 'sweetalert2';


export default function NotifsConfigTable({}) {
  const {t} = useTranslation();
  const {clientId, devices, retiredDevices} = useConfig().state.config;
  const [loading, setLoading] = useState(false);
  const {state: {configs}, dispatch} = useNotificationsContext();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('timestamp');
  const devicesDict = useMemo(() => _.keyBy([...devices, ...(retiredDevices || [])], 'serialNo'), [devices, retiredDevices]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const tryDelete = (timestamp, substance, period) =>
    Swal.fire({
      title: `${t('episodes.deleteNotifQuestion')} ${substance} (${t('episodes', {returnObjects: true})[period] || period})?`,
      icon: 'question',
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: t('general.yes'),
      denyButtonText: t('general.no'),
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        delEpisodesConfig(clientId, timestamp)
          .then(res => {
            dispatch({type: 'set-configs', configs: configs.filter(c => c.timestamp !== timestamp)});
            Swal.fire(t('episodes.notificationDeleted'), '', 'success');
          })
          .catch(err => Swal.fire(t('swal.errorMessage'), err.response?.data, 'error'))
          .finally(() => setLoading(false));
      }
    });

  const tryToggleEnable = (timestamp, enabled, substance, period) =>
    Swal.fire({
      title: `${!enabled ? t('episodes.activateNotifications') : t('episodes.deactivateNotifications')} ${substance} (${t('episodes', {returnObjects: true})[period] || period}) ?`,
      icon: 'question',
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: t('general.yes'),
      denyButtonText: t('general.no'),
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        enableEpisodeConfig(clientId, timestamp, !enabled)
          .then(res => {
            dispatch({
              type: 'set-configs',
              configs: configs.map(c => c.timestamp === timestamp ? {...c, enabled: !enabled} : c)
            });
            Swal.fire(`${!enabled ? t('episodes.activatedNotification') : t('episodes.deactivatedNotification')}.`, '', 'success');
          })
          .catch(err => Swal.fire(t('swal.errorMessage'), err.response?.data, 'error'))
          .finally(() => setLoading(false));
      }
    });

  useEffect(() => {
    setLoading(true);
    getEpisodesConfig(clientId)
      .then(result => dispatch({type: 'set-configs', configs: result}))
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [clientId]);

  const rows = useMemo(() => configs.map(({
                                            timestamp,
                                            substance,
                                            stations,
                                            level,
                                            threshold,
                                            thresholdLow,
                                            period,
                                            smsRecipients,
                                            emailRecipients,
                                            enabled
                                          }) => ({
    timestamp,
    substance,
    stations,
    level: level,
    levelLabel: t('episodes.severity.' + level),
    threshold,
    thresholdLow,
    period,
    smsRecipients,
    emailRecipients,
    enabled
  })), [configs]);


  return <Box display="flex">
    {loading ? <CircularProgress size={100} sx={{margin: 'auto', marginTop: 10}}/> :
      <TableContainer>
        <Table aria-label="Notifications Config table">
          <TableHead>
            <TableRow sx={{backgroundColor: 'whitesmoke'}}>
              <TableCell width={20}><THeader>#</THeader></TableCell>
              {['substance', 'stationsNames', 'level', 'threshold', 'period', 'recipients']
                .map(id =>
                  <TableCell key={id} sortDirection={orderBy === id ? order : false}
                             align="center">
                    <TableSortLabel
                      active={orderBy === id}
                      direction={orderBy === id ? order : 'asc'}
                      onClick={event => handleRequestSort(event, id)}
                    >
                      <Typography sx={{color: primaryColor[2]}}>
                        {t('episodes.' + id)}
                      </Typography>
                      {orderBy === id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                )}
              <TableCell align="center">
                <Typography sx={{color: primaryColor[2]}}>
                  {/*{t('episodes.')}*/}
                  {t("actions")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
                ? stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : stableSort(rows, getComparator(order, orderBy))
            ).map((row, idx) => {
              const {
                timestamp,
                substance,
                stations,
                level,
                levelLabel,
                threshold,
                thresholdLow,
                period,
                smsRecipients,
                emailRecipients,
                enabled
              } = row;
              return <TableRow
                key={idx}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
              >
                <TableCell width={20} align={'center'}><Typography
                  color={grayColor[1]}>{idx + 1}</Typography></TableCell>
                <TableCell align="center"><Typography color={grayColor[1]}>
                  {t('measurements.parameters.' + substance)}
                </Typography></TableCell>
                <TableCell align="center">
                  <DevicesCell stations={stations} devicesDict={devicesDict}/>
                </TableCell>
                <TableCell align="center">
                  <EpiCell episodeType={level}
                           title={substance === "PM10" ? t('episodes.airExplanations.' + level) : t('episodes.explanations.' + level)}>{levelLabel}</EpiCell>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    color={grayColor[1]}>{!!thresholdLow && (thresholdLow + ' - ')} {threshold} [{unitsByParams(substance)}]</Typography>
                </TableCell>
                <TableCell align="center"><Typography
                  color={grayColor[1]}>{t('episodes', {returnObjects: true})[period] || period}</Typography></TableCell>
                <TableCell align="center">
                  <RecipientsCell emailRecipients={emailRecipients} smsRecipients={smsRecipients}/>
                </TableCell>
                <TableCell align="center">
                  <Box display={'flex'} flexWrap={'wrap'}>
                    <Switch sx={{alignSelf: 'center', justifySelf: 'center'}} defaultChecked defaultValue={enabled}
                            size={'small'}
                            checked={enabled}
                            onClick={() => tryToggleEnable(timestamp, enabled, substance, period)}
                            color={'success'}/>
                    <Button
                      color={'primary'}
                      size={'large'}
                      onClick={() => dispatch({type: 'edit-config', devices, config: row})}
                    >
                      <Edit/>
                    </Button>

                    <Button
                      color={'error'}
                      size={'large'}
                      onClick={() => tryDelete(timestamp, substance, period)}
                    >
                      <DeleteSweep/>
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>;
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                sx={{border: 'none'}}
                translate={'yes'}
                rowsPerPageOptions={[5, 10, 25, 50, {label: t('general.all'), value: -1}]}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={t('general.rowsPerPage')}
                labelDisplayedRows={({
                                       from,
                                       to,
                                       count
                                     }) => `${from}–${to} z ${count !== -1 ? count : `${t('general.moreThan')} ${to}`}`}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    }
  </Box>;
}

const colorBySeverity = {
  'ALLOWABLE': primaryColor[0],
  'INFORM': warningColor[0],
  'ALARM': dangerColor[0]
};

export const EpiCell = ({children, episodeType, title}) => <Tooltip placement={'right-end'} title={title}><Typography
  fontWeight={'bold'}
  color={colorBySeverity[episodeType] || primaryColor[1]}>
  {children}
</Typography></Tooltip>;

export const DevicesCell = ({stations, devicesDict}) => {
  const [expand, setExpand] = useState(false);
  const isTablet = useMediaQuery(`(min-width:${TABLET_SCREEN})`);
  const isDesktop = useMediaQuery(`(min-width:${DESKTOP_SCREEN})`);
  const maxStations = isDesktop ? 3 : (isTablet ? 1 : 0);

  return <Box display={'flex'} flexWrap={'wrap'} gap={1}>
    {stations.slice(0, expand ? stations.length : maxStations).map(serialNo => {
      const d = devicesDict[serialNo];
      return <Chip
        sx={{paddingTop: 2, paddingBottom: 2}}
        variant={'outlined'}
        key={serialNo}
        label={d.name}
        color={'primary'}
        deleteIcon={<Tooltip title={'Zobacz w GoogleMaps'}><NearMe/></Tooltip>}
        onDelete={() => openInNewTab(`https://www.google.com/maps/place/${d.location[1]},${d.location[0]}`)}
      />;
    })}

    {expand && <Button onClick={() => setExpand(false)}><ChevronLeft/></Button>}

    {stations.length > maxStations && !expand &&
    <Button
      sx={{textDecoration: 'underline'}}
      size={'large'}
      variant={'text'}
      onClick={() => setExpand(true)}>_+{stations.length - maxStations}</Button>}
  </Box>;
};


const RecipientsCell = ({emailRecipients, smsRecipients}) => {
  const [expand, setExpand] = useState(false);
  const emailDisabled = !emailRecipients.length;
  const phoneDisabled = !smsRecipients.length;

  const expandHandler = type => type === expand ? setExpand(false) : setExpand(type);

  return <Box display={'flex'} alignItems={'flex-start'} flexDirection={expand ? 'column' : 'row'}>
    {expand !== 'phone' && <Button disabled={emailDisabled} sx={{ml: -2, opacity: emailDisabled ? 0.5 : 1.0}}
                                   onClick={() => expandHandler('e-mail')}
    >
      <Badge badgeContent={emailRecipients.length} color="info">
        <img src={MailiOS}/>
      </Badge>
      {!emailDisabled && expand === 'e-mail' ? <ExpandLess sx={{position: 'absolute', right: 0, bottom: -5}}/> :
        <ExpandMore sx={{position: 'absolute', right: 0, bottom: -5}}/>}
    </Button>}

    {expand === 'e-mail' && <Box mt={1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
      {emailRecipients.map(email => <Typography lineHeight={1.5} color={grayColor[1]}
                                                variant={'p'}>{email}</Typography>)}
    </Box>}

    {expand !== 'e-mail' && <Button disabled={phoneDisabled} sx={{opacity: phoneDisabled ? 0.5 : 1.0}}
                                    onClick={() => expandHandler('phone')}>
      <Badge badgeContent={smsRecipients.length} color="success">
        <img src={PhoneiOS}/>
      </Badge>

      {!phoneDisabled && (expand === 'phone' ?
        <ExpandLess sx={{color: successColor[2], position: 'absolute', right: 0, bottom: -5}}/> :
        <ExpandMore sx={{color: successColor[2], position: 'absolute', right: 0, bottom: -5}}/>)}
    </Button>}
    {expand === 'phone' && <Box mt={1} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
      {smsRecipients.map(phone => <Typography lineHeight={1.5} color={grayColor[1]}
                                              variant={'p'}>{formatPhoneNumber(phone.replace("+48", ""))}</Typography>)}
    </Box>}
  </Box>;

};
