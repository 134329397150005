import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia, IconButton,
  InputLabel,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import {Add, Delete, Refresh, Remove} from '@mui/icons-material';
import AdapterMoment from '@mui/lab/AdapterMoment';
import {DateTimePicker, LoadingButton, LocalizationProvider} from '@mui/lab';
import {editMaintenance} from '../../api';
import {dangerColor, warningColor} from '../../assets/jss/nextjs-material-dashboard';
import {NEW} from '../../views/MaintenanceStatus';
import {FAILURE, INCIDENT, SERVICE, UNKNOWN} from '../../views/MaintenanceType';

const GPNT_LOCATION = ['54.35452194576222', '18.595159542630974'];
const IMG_NOT_AVAILABLE = 'https://s3.eu-central-1.amazonaws.com/files.coral.com/image-not-available.png';

const MaintenanceForm = ({maintenance, onClose, email}) => {
  const [formData, setFormData] = useState({...maintenance});
  const [isInMaintenance, setIsInMaintenance] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // console.log({maintenance, formData});

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    editMaintenance(formData.serialNo, formData.clientId, formData, isInMaintenance)
      .then(result => {
        // setFormData(result);
        // close with refresh parent with maintenance List
        onClose(true);
      })
      .catch(err => {
        console.error(err);
        setErrorMessage(err.message);
      })
      .finally(() => setLoading(false));
  };

  const refresh = () => {
    setFormData({...maintenance});
  };
  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNestedChange = (name, value) => {
    setFormData({
      ...formData,
      description: {
        ...formData.description,
        [name]: value,
      },
    });
  };

  const handleStartPhotoChange = (e) => {
    const {name, value} = e.target;
    setFormData({
      ...formData,
      startPhoto: [{
        ...formData.startPhoto[0],
        [name]: value,
      }],
    });
  };

  const handleEndPhotoChange = (e) => {
    const {name, value} = e.target;
    setFormData({
      ...formData,
      endPhoto: [{
        ...formData.endPhoto[0],
        [name]: value,
      }],
    });
  };

  const handleNoteChange = (idx, name, value) => {
    formData.notes[idx][name] = value;
    setFormData({...formData});
  };

  const addDescription = () => setFormData({
    ...formData,
    description: {
      text: '',
      createdAt: moment().toISOString(),
      createdBy: email,
    },
  });
  const removeDescription = () => {
    delete formData.description;
    setFormData({
      ...formData
    });
  };

  const addStartPhoto = () => setFormData({
    ...formData,
    startPhoto: [{
      url: IMG_NOT_AVAILABLE,
      timestamp: moment().toISOString(),
      createdBy: email,
      lat: GPNT_LOCATION[0],
      lon: GPNT_LOCATION[1]
    }],
  });

  const removeStartPhoto = () => {
    delete formData.startPhoto;
    setFormData({
      ...formData
    });
  };

  const addEndPhoto = () => setFormData({
    ...formData,
    endPhoto: [{
      url: IMG_NOT_AVAILABLE,
      timestamp: moment().toISOString(),
      createdBy: email,
      lat: GPNT_LOCATION[0],
      lon: GPNT_LOCATION[1]
    }],
  });

  const removeEndPhoto = () => {
    delete formData.endPhoto;
    setFormData({
      ...formData
    });
  };

  const addNote = () => setFormData({
    ...formData,
    notes: [...(formData.notes || []), {text: '', createdAt: moment().toISOString(), createdBy: email}],
  });

  const removeNote = (idx) => {
    setFormData({
      ...formData,
      notes: formData.notes.filter((_, index) => index !== idx)
    });
  };

  //  close on key 'q'
  useEffect(() => {
    const handleKeyDown = (event) => {
      console.log(event);
      if (event.key === 'q') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  return (<LocalizationProvider dateAdapter={AdapterMoment}>
      <Paper elevation={4} sx={{padding: 4, minHeight: '100vh'}}>
        <IconButton
          onClick={refresh}
          sx={{position: 'absolute', top: 80, left: 30}}
          color={'primary'}>
          <Refresh/>
        </IconButton>
        <form onSubmit={handleSubmit}>
          {errorMessage && <Typography color={dangerColor[0]}>{errorMessage}</Typography>}
          <Box display={'flex'} flexWrap={'wrap'} gap={5}>
            <Box width={300} pt={3} display={'flex'} flexDirection={'column'} gap={2}>
              <TextField
                label="SerialNo"
                name="serialNo"
                value={formData.serialNo}
                onChange={handleChange}
                // disabled
                fullWidth
              />
              {/* STATUS */}
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  defaultValue={formData.status || NEW}
                  fullWidth
                >
                  <MenuItem value="NEW">NEW</MenuItem>
                  <MenuItem value="STARTED">STARTED</MenuItem>
                  <MenuItem value="IN_PROGRESS">IN_PROGRESS</MenuItem>
                  <MenuItem value="DONE">DONE</MenuItem>
                </Select>
              </FormControl>

              {/* TYPE */}
              <FormControl fullWidth>
                <InputLabel>Typ</InputLabel>
                <Select
                  label="Type"
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  defaultValue={formData.type || SERVICE}
                  fullWidth
                >
                  <MenuItem value="UNKNOWN">{UNKNOWN}
                    <Typography color={'gray'} variant={'caption'}> - Przyczyna jeszcze nieznana</Typography>
                  </MenuItem>
                  <MenuItem value="SERVICE">{SERVICE}
                    <Typography color={'green'} variant={'caption'}> - Zaplanowany serwis</Typography>
                  </MenuItem>
                  <MenuItem value="INCIDENT">{INCIDENT}
                    <Typography color={warningColor[2]} variant={'caption'}> - Niespodziewane zdarzenie</Typography>
                  </MenuItem>
                  <MenuItem value="FAILURE">{FAILURE}
                    <Typography color={'error'} variant={'caption'}> - Awaria po naszej stronie</Typography></MenuItem>
                </Select>
              </FormControl>

              <DateTimePicker
                label="createdAt"
                value={moment(formData.createdAt)}
                onChange={(newDate) => handleChange({target: {name: 'createdAt', value: newDate.valueOf()}})}
                renderInput={(params) => <TextField {...params} fullWidth/>}
                // inputFormat="L HH:mm" // Ustawienie formatu
                // mask={null} // Wyłączenie maskowania
              />

              <TextField
                label="createdBy"
                name="createdBy"
                value={formData.createdBy}
                onChange={handleChange}
                fullWidth
              />
              <DateTimePicker
                label="updatedAt"
                value={moment(formData.updatedAt)}
                onChange={(newDate) => handleChange({target: {name: 'updatedAt', value: newDate.valueOf()}})}
                renderInput={(params) => <TextField {...params} fullWidth/>}
              />
              <TextField
                label="updatedBy"
                name="updatedBy"
                value={formData.updatedBy}
                onChange={handleChange}
                fullWidth
              />
            </Box>

            {/*  DESCRIPTION - SECTION */}
            <Box width={400} pt={3} display={'flex'} flexDirection={'column'} gap={2} position={'relative'}>
              <TextField
                label="initialText"
                name="initialText"
                value={formData.initialText}
                onChange={handleChange}
                multiline
                rows={4}
                fullWidth
              />
              {formData.description ? <>
                <IconButton onClick={removeDescription} sx={{position: 'absolute', right: -10, top: 160, zIndex: 20}}
                            color={'error'}>
                  <Delete/>
                </IconButton>
                <TextField
                  label="Description"
                  name="text"
                  value={formData.description.text}
                  onChange={(e) => handleNestedChange('text', e.target.value)}
                  multiline
                  rows={4}
                  fullWidth
                />
                <DateTimePicker
                  label="desc - createdAt"
                  value={moment(formData.description.createdAt)}
                  onChange={(newDate) => handleNestedChange('createdAt', newDate.toISOString())}
                  renderInput={(params) => <TextField {...params} fullWidth/>}
                />
                <TextField
                  label="desc - createdBy"
                  name="createdBy"
                  value={formData.description.createdBy}
                  onChange={(e) => handleNestedChange('createdBy', e.target.value)}
                  fullWidth
                /></> : <Button variant={'contained'} color={'primary'} onClick={addDescription}>
                Dodaj Description
              </Button>}
            </Box>


            <Box width={formData.startPhoto ? 600 : 250} mt={-3} display={'flex'} flexDirection={'column'} gap={2}
                 position={'relative'}>
              <Typography variant="h6">Start Photo</Typography>
              {formData.startPhoto ? <>
                  <IconButton onClick={removeStartPhoto} sx={{position: 'absolute', right: -10, top: -10}}
                              color={'error'}>
                    <Delete/>
                  </IconButton>
                  {formData.startPhoto.map((photo, index) => (
                    <Card key={index} sx={{marginBottom: 2}}>
                      <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <CardMedia
                          component="img"
                          image={photo.url}
                          alt="Start Photo"
                          sx={{width: '50%', objectFit: 'cover'}}
                        />
                        <CardContent sx={{flex: 1, display: 'flex', flexDirection: 'column', gap: 3}}>
                          <TextField
                            label="url"
                            name="url"
                            value={photo.url}
                            onChange={handleStartPhotoChange}
                            fullWidth
                          />
                          <DateTimePicker
                            label="timestamp"
                            value={moment(photo.timestamp)}
                            onChange={(newDate) => handleStartPhotoChange({
                              target: {
                                name: 'timestamp',
                                value: newDate.toISOString()
                              }
                            })}
                            renderInput={(params) => <TextField {...params} fullWidth/>}
                          />
                          <TextField
                            label="createdBy"
                            name="createdBy"
                            value={photo.createdBy}
                            onChange={handleStartPhotoChange}
                            fullWidth
                          />
                          <TextField
                            label="lat"
                            name="lat"
                            value={photo.lat}
                            onChange={handleStartPhotoChange}
                            fullWidth
                          />
                          <TextField
                            label="lon"
                            name="lon"
                            value={photo.lon}
                            onChange={handleStartPhotoChange}
                            fullWidth
                          />
                          <a target={'_blank'} href={`http://www.google.com/maps/place/${photo.lat},${photo.lon}`}>
                            <Typography variant="body2" color="info">
                              {photo.lat?.slice(0, 10)},{photo.lon?.slice(0, 10)}
                            </Typography>
                          </a>
                        </CardContent>
                      </Box>
                    </Card>
                  ))}
                </> :
                <Button sx={{mt: 3}} variant={'contained'} onClick={addStartPhoto} startIcon={<Add/>}>Dodaj Start
                  Photo</Button>
              }
            </Box>

            <Box width={formData.endPhoto ? 600 : 250} mt={-3} display={'flex'} flexDirection={'column'} gap={2}
                 position={'relative'}>
              <Typography variant="h6">End Photo</Typography>
              {formData.endPhoto ? <>
                  <IconButton onClick={removeEndPhoto} sx={{position: 'absolute', right: -10, top: -10}} color={'error'}>
                    <Delete/>
                  </IconButton>
                  {formData.endPhoto.map((photo, index) => (
                    <Card key={index} sx={{marginBottom: 2}}>
                      <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <CardMedia
                          component="img"
                          image={photo.url}
                          alt="Start Photo"
                          sx={{width: '50%', objectFit: 'cover'}}
                        />
                        <CardContent sx={{flex: 1, display: 'flex', flexDirection: 'column', gap: 3}}>
                          <TextField
                            label="url"
                            name="url"
                            value={photo.url}
                            onChange={handleEndPhotoChange}
                            fullWidth
                          />
                          <DateTimePicker
                            label="timestamp"
                            value={moment(photo.timestamp)}
                            onChange={(newDate) => handleEndPhotoChange({
                              target: {
                                name: 'timestamp',
                                value: newDate.toISOString()
                              }
                            })}
                            renderInput={(params) => <TextField {...params} fullWidth/>}
                          />
                          <TextField
                            label="createdBy"
                            name="createdBy"
                            value={photo.createdBy}
                            onChange={handleEndPhotoChange}
                            fullWidth
                          />
                          <TextField
                            label="lat"
                            name="lat"
                            value={photo.lat}
                            onChange={handleEndPhotoChange}
                            fullWidth
                          />
                          <TextField
                            label="lon"
                            name="lon"
                            value={photo.lon}
                            onChange={handleEndPhotoChange}
                            fullWidth
                          />
                          <a target={'_blank'} href={`http://www.google.com/maps/place/${photo.lat},${photo.lon}`}>
                            <Typography variant="body2" color="info">
                              {photo.lat?.slice(0, 10)},{photo.lon?.slice(0, 10)}
                            </Typography>
                          </a>
                        </CardContent>
                      </Box>
                    </Card>
                  ))}
                </> :
                <Button sx={{mt: 3}} variant={'contained'} onClick={addEndPhoto} startIcon={<Add/>}>Dodaj End
                  Photo</Button>
              }
            </Box>

            <Box mt={2} display={'flex'} gap={3} alignItems={'flex-end'}>
              {(formData?.notes || []).map((note, idx) =>
                <Card key={idx} elevation={4}>
                  <CardContent>
                    <Box width={300} display={'flex'} flexDirection={'column'} gap={2} position={'relative'}>
                      <Typography>#{idx + 1}</Typography>
                      <IconButton onClick={() => removeNote(idx)} sx={{position: 'absolute', right: -10, top: -10}}
                                  color={'error'}>
                        <Delete/>
                      </IconButton>
                      <TextField
                        label="Notka"
                        name="text"
                        value={note.text}
                        onChange={(e) => handleNoteChange(idx, 'text', e.target.value)}
                        multiline
                        rows={4}
                        fullWidth
                      />
                      <DateTimePicker
                        label="createdAt"
                        value={moment(note.createdAt)}
                        onChange={(newDate) => handleNoteChange(idx, 'createdAt', newDate.toISOString())}
                        renderInput={(params) => <TextField {...params} fullWidth/>}
                      />
                      <TextField
                        label="createdBy"
                        name="createdBy"
                        value={note.createdBy}
                        onChange={(e) => handleNoteChange(idx, 'createdBy', e.target.value)}
                        fullWidth
                      />
                    </Box>
                  </CardContent>
                </Card>
              )}
              <Button sx={{mt: 3, height: 50}} variant={'contained'} onClick={addNote} startIcon={<Add/>}>Dodaj
                Notatkę</Button>
            </Box>
          </Box>
          <Box mt={2} display={'flex'} gap={5}>
            <LoadingButton disabled={!formData.serialNo} loading={loading} sx={{width: 200}} type="submit"
                           variant="contained" color="primary">
              Save
            </LoadingButton>
            <LoadingButton loading={loading} sx={{width: 200}} variant="contained" color="error" onClick={onClose}>
              Close
            </LoadingButton>
          </Box>
        </form>
      </Paper>
    </LocalizationProvider>
  );
};

export default MaintenanceForm;
